// FirebaseAuth.js
import { auth } from "./firebaseConfig";
import { GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoggedIn } from "../reducers/userReducer";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const FirebaseAuth = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const isLoggin = useSelector((state: any) => state.user.isLoggin);
  //const user = useSelector((state:any)=> state.user.user)
  const user = auth.currentUser;
  const handleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider) as any;
      //dispatch(setAccessToken(result.user.accessToken));
      //dispatch(setUser(result.user));
      localStorage.setItem('access_token_fb_auth', result.user.accessToken);
      console.log("user login: ", result.user)
    } catch (error) {
      console.error("Error logging in: ", error);
    }
  };
  const handleLogout = async () => {
    try {
      await signOut(auth);
      localStorage.removeItem('access_token_fb_auth');
    } catch (error) {
      console.error("Error logging out: ", error);
    }
  };
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      console.log('Auth state changed:', user);
      //dispatch(setUser(user));
      dispatch(setIsLoggedIn(!!user));
    });
    return () => unsubscribe();
  }, [dispatch]);

  return (
    <div>
      {isLoggin && user ? (
        <div className="user-info container">
          <img src={user.photoURL || ''} alt="User Avatar" className="user-avatar" />
          <p>{user.displayName}</p>
          <button onClick={handleLogout} className="login-button">
            <span className="logout">
              {translate('logout')}
            </span>
            <i className="fa fa-sign-out" aria-hidden="true"></i>
          </button>
        </div>
      ) : (
        <button onClick={handleLogin} className="login-button">
          <img className="google-icon" src="https://developers.google.com/static/identity/images/branding_guideline_sample_lt_rd_sl.svg?hl=vi" alt="Google Icon" width="20" height="20" />
          <span>{translate('logingoogle')}</span>
        </button>
      )}
    </div>
  );
};

export default FirebaseAuth;
