import { useEffect, useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import LazyLoad from 'react-lazyload';

const GalleryImageComponent = (props) => {
	const { imgList = [], activeImg } = props;
	const [activeItem, setActiveItem] = useState(activeImg);
	const [isMobile, setIsMobile] = useState(false);
	useEffect(() => {
		if (window.innerWidth <= 1038) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	}, [activeItem, setActiveItem, isMobile]);

	const getColItem = (imgList, number) => {
		const size = Math.floor(imgList.length / number);
		const remainder = imgList.length % number;
		const anchor = size + (remainder > 0 ? 1 : 0);
		if (number === 2) {
			const part1 = imgList.slice(0, anchor);
			const part2 = imgList.slice(anchor);
			return [part1, part2];
		} else if (number === 3) {
			const part1 = imgList.slice(0, anchor);
			const part2 = imgList.slice(anchor, 2 * anchor);
			const part3 = imgList.slice(2 * anchor);
			return [part1, part2, part3]
		}
		return [];
	}
	const colItem = isMobile ? getColItem(imgList,2) : getColItem(imgList,3);
	return (
		<div className="gallery-container gallery-container-1">
			{
				colItem.map((part) => {
					return (
						<div className={`container display-flex-col fs-gallery-col ${isMobile ? 'w-49' : 'w-32'}`}>
							{
								part.map((item, index) => {
									return (
										<div className="gallery-try-item" key={`gallery-item-${index + 1}`}>
											<LazyLoad
												height={100}
												offset={[-100, 100]}>
												<img className="gallery-img" src={item} alt={`demo art ${index + 1}`} />
											</LazyLoad>
										</div>
									)
								})
							}
						</div>
					)
				})
			}
		</div>

	);
};

export default GalleryImageComponent;