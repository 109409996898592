// counterSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = { 
    selectedImage: '',
    transformedImage: '',
    base64Img:'',
    base64Target:'',
    progress: 0,
    isLoading:false,
    hasError:false,
    imgResult:{url_anime:'', url_origin:'', url_concated:'', url_share_page:''},
    queue: 0,
    second: -1,
    isModelOpen: false,
    count: 0 //test
 };
const imgSlice = createSlice({
  name: 'img',
  initialState: initialState,
  reducers: {
    selectImg: (state, action) => { 
        state.selectedImage = action.payload;
        state.base64Img = action.payload
    },
    transformImg: (state,action) => {
        state.transformedImage = action.payload;
    },
    setLoading: (state,action) => {
      state.isLoading = action.payload
    },
    setHasError: (state,action) => {
      state.hasError = action.payload
    }
  }
});

export const { selectImg, transformImg, setLoading, setHasError } = imgSlice.actions;
export default imgSlice.reducer;

