import { useEffect, useRef, useState } from "react";
import utf8 from 'utf8';
import { IMAGE_TYPE, api_url, api_path_request_job, api_path_token, token_params, api_path_request_job_progress, ErrorHttpStatusCode, JOB_PROGRESS_INTERVAL, FAKE_PROGRESS_INTERVAL, PAYLOAD_IMG_MODEL, QUEUE_JOB_PROGRESS_INTERVAL, REF_IMGS } from "../../constant/constant";
import 'react-circular-progressbar/dist/styles.css';
import ImageComponent from "../ImageComponent/ImageComponent";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { postHref } from "../../library/httpMethods";
import { AxiosError } from "axios";
import { convertImgSrc, getUrlParameter, resizeFile } from "../../utils/util";
import { useTranslation } from "react-i18next";
import QueueModal from "../Modal/QueueModal";
import { FileUploader } from "react-drag-drop-files";
import TrialImageComponent from "../ImageComponent/TrialImageComponent";
import ButtonListComponent from "../ButtonComponent/ButtonListComponent";
import { ImgItem } from "../../constant/types";
import { FacebookIcon, FacebookShareButton } from "react-share";
import AutorigButton from "../ButtonComponent/ButtonComponent";

const ImageTransformation = () => {
	const [selectedImage, setSelectedImage] = useState(undefined);
	const [transformedImage, setTransformedImage] = useState(undefined);
	const [base64Img, setBase64Img] = useState(undefined);
	const [base64Target, setBase64Target] = useState(undefined);
	const [progress, setProgressBarValue] = useState(0);
	const [accessToken, setAccessToken] = useState('');
	const [isLoading, setLoading] = useState(false);
	const [hasError, setHasError] = useState(false);
	const [imgResult, setImgResult] = useState({url_anime:'', url_origin:'', url_concated:'', url_share_page:''});
	const [queue, setQueue] = useState(0);
	const [second, setSecond] = useState(-1);
	const [isModelOpen, setModelOpen] = useState(false);
	
	const interval = useRef<ReturnType<typeof setInterval> | undefined>(undefined)
	const { t: translate } = useTranslation();
	const navigate = useNavigate();
	const handleChange = async (event: any) => {
		try {
			const file = event.target?.files[0] || event;
			const image = await resizeFile(file);
			setSelectedImage(image);
			setBase64Img(image);
		} catch (err) {
			console.log(err);
		}
	};

	const getImgList = (imgList:string[]) => {
		const finalImgList: ImgItem[] = [{
			id: 'targetImg',
			img: ''
		}];
		imgList.map((item:string)=>{
			let imgItem:ImgItem = {
				id: "",
				img: ""
			}
			const imgFolder = getImgFolder(item);
			const itemSplitArr = item.split("-");
			if(item.includes(".")){
				imgItem.id = item.split(".")[0];
				imgItem.img = `https://share.1clickfaceswap.click/static_reactor/${imgFolder}/${itemSplitArr[itemSplitArr.length-1]}.webp`;
			} else {
				imgItem.id = item;
				imgItem.img = `https://share.1clickfaceswap.click/static_reactor/${imgFolder}/${itemSplitArr[itemSplitArr.length-1]}.jpg`
			}
			return finalImgList.push(imgItem);
		})
		return finalImgList;
	}
	const getImgFolder=(item)=>{
		const folderList = ['actor_actress','chautinhtri','meme','mrbean','people','superhero'];
		return folderList.find((folder:string)=>item.includes(folder));
	}
	
	const [tryImg, setTryImg] = useState(getImgList(REF_IMGS)[1]);
	

	const handleOnImageRemove = () => {
		setSelectedImage(undefined);
		setBase64Img(undefined);
	};

	const handleTransfer = (base64Img: any, base64Target:any) => {
		setProgressBarValue(0);
		setHasError(false);
		interval.current = undefined;
		if (base64Img) {
			const encode_image_utf8 = utf8.encode(base64Img);
			const encode_target_utf8 = base64Target ? utf8.encode(base64Target): '';
			const jobuuid = uuidv4();
			setTransformedImage(undefined);
			if (accessToken) {
				requestGeningImg(jobuuid, encode_image_utf8,encode_target_utf8);
			} else {
				getRequestToken(jobuuid, encode_image_utf8,encode_target_utf8);
			}
		}
	}
	const handleFakeProgress = () => {
		let fakeProgress = 0;
		interval.current = setInterval(() => {
			if (fakeProgress === 99) {
				clearInterval(interval.current);
				fakeProgress = 0;
			} else {
				fakeProgress++;
				setProgressBarValue(fakeProgress);
			}
		}, FAKE_PROGRESS_INTERVAL);
	}

	const requestGeningImg = (jobId: string, img: string, targetImg:string, token: string = "") => {
		const requestJobUrl = api_url + api_path_request_job;
		const requestJobProgress = api_url + api_path_request_job_progress;
		const jobPayload = {
			job_id: jobId,
			type: PAYLOAD_IMG_MODEL,
			target : tryImg.id,
			img64: img,
			target_img64: targetImg

		};
		const jobProgressPayload = {
			job_id: jobId
		}
		const requestHeaders = { Authorization: `Bearer ${token || accessToken}`, };
		setLoading(true);

		postHref(requestJobUrl, JSON.stringify(jobPayload, null, 2), requestHeaders).then((res: any) => {
			if (res.data) {
				if (res.data.error) {
					handleError(res.data.error, jobId, img, targetImg);
					return;
				}
				if (res.data.num_waiting_job > 0) {
					setQueue(res.data.num_waiting_job);
					handleOpenModel();
					let genQueue = res.data.num_waiting_job;
					let genSecond = res.data.num_waiting_job * (QUEUE_JOB_PROGRESS_INTERVAL / 1000);
					setSecond(genSecond);
					let countDownQueue = setInterval(() => {
						genQueue--;
						setQueue(genQueue);
						if (genQueue === 0) {
							handleJobProgressRequest(requestJobProgress, jobProgressPayload, requestHeaders, jobId, img,targetImg);
							clearInterval(countDownQueue);
						}
					}, QUEUE_JOB_PROGRESS_INTERVAL);
					let countDownSecond = setInterval(() => {
						genSecond--;
						setSecond(genSecond);
						if (genSecond === 0) {
							clearInterval(countDownSecond);
						}
					}, 1000)
				} else {
					handleJobProgressRequest(requestJobProgress, jobProgressPayload, requestHeaders, jobId, img, targetImg);
				}
			}
		}).catch((err: AxiosError) => {
			handleError(err, jobId, img,targetImg);
		})
	}

	const handleJobProgressRequest = (requestJobProgress, jobProgressPayload, requestHeaders, jobId, img, targetImg) => {
		if (!interval.current) {
			handleFakeProgress();
		}
		let jobProgressRequest = setInterval(() => {
			postHref(requestJobProgress, JSON.stringify(jobProgressPayload, null, 2), requestHeaders).then((res: any) => {
				if (res.data) {
					if (res.data.error) {
						handleError(res.data.error, jobId, img, targetImg);
						clearInterval(jobProgressRequest);
						return;
					}
					const { progress } = res.data;
					if (progress && progress < 100) {
						//setProgressBarValue(progress);
					} else if (progress === 100) {
						const { url_anime, url_origin, url_concated, url_share_page } = res.data;
						const responseTransformedImg = "https://" + url_anime;
						setTransformedImage(responseTransformedImg as any);
						setImgResult({ url_anime, url_origin, url_concated, url_share_page });
						setLoading(false);
						clearInterval(jobProgressRequest);
					}
				}
			}).catch((err: AxiosError) => {
				clearInterval(jobProgressRequest);
				handleError(err, jobId, img, targetImg);
			})
		}, JOB_PROGRESS_INTERVAL);
	}

	const getRequestToken = (jobId: string, img: string, targetImg:string) => {
		const reuestTokenUrl = api_url + api_path_token;
		setLoading(true);
		postHref(reuestTokenUrl, JSON.stringify(token_params, null, 2)).then((res: any) => {
			if (res.data) {
				if (res.data.error) {
					handleError(res.data.error, jobId, img,targetImg);
					return;
				}
				const token = res.data["access_token"];
				setAccessToken(token as any);
				localStorage.setItem('access_token', token);
				requestGeningImg(jobId, img,targetImg, token);
			}
		}).catch(err => { handleError(err, jobId, img, targetImg); })
	}

	const handleError = (err: AxiosError, jobId: string, img: string,targetImg:string) => {
		switch (err.response?.status) {
			case ErrorHttpStatusCode.NUMBER_401:
				//popup ads display handling
				getRequestToken(jobId, img,targetImg);
				break;
			default:
				setProgressBarValue(0);
				setLoading(false);
				setHasError(true);
				break;
		}
		console.log(err);
	}

	useEffect(() => {
		const token = localStorage.getItem('access_token');
		if (token) {
			setAccessToken(token);
		}
		if (transformedImage && interval.current) {
			clearInterval(interval.current);
			setProgressBarValue(100);
		}
	}, [transformedImage, setProgressBarValue])

	const UploadImageComponent = () => {
		const fileTypes = ["JPG", "PNG"];

		return (
			<div className="container display-flex-col image-container">
				{selectedImage && (<ImageComponent type={IMAGE_TYPE.UPLOAD} progressBar={false} alt={"Your Upload Photo"} imageState={selectedImage} onClose={handleOnImageRemove} />)}
				{!selectedImage && (<FileUploader handleChange={handleChange} fileTypes={fileTypes} name="file" classes="drag-drop-img fs-img-holder container" />)}

			</div>
		);
	}

	const DemoImageComponent = () => {

		return (
			<div className="container display-flex-col image-container">
				{(tryImg) && (<ImageComponent type={IMAGE_TYPE.UPLOAD} progressBar={false} alt={"Your Upload Photo"} imageState={tryImg.img} onClose={handleOnImageRemove} hideCloseBtn/>)}

			</div>
		);
	}
	const TransformedImageComponent = () => {
		const transformedImgBtnList = [
			// {
			// 		id: "transfer",
			// 		icon:"fa-solid fa-arrows-rotate",
			// 		text: "generate",
			// 		action: () => { handleTransfer(base64Img) }
			// },
			{
				id: "share",
				icon: "fa-solid fa-share",
				text: "shareAnimePhoto",
				action: () => {
					if (transformedImage) {
						navigate(getUrlParameter(transformedImage), { state: imgResult });
					}
				}
			},
			{
				id: "download",
				icon: "fa-solid fa-download",
				text: "downloadAnimePhoto",
				action: () => {
					if (!transformedImage) return;
					const url = transformedImage;
					const link = document.createElement('a');
					link.href = url;
					link.target = '_blank';
					link.rel = 'noopener noreferrer';
					link.setAttribute('download', 'your-image');
					document.body.appendChild(link);
					link.click();
					link.parentNode?.removeChild(link);
					window.URL.revokeObjectURL(url);
				}
			}
		]
		return (
			<div className="container display-flex-col image-container">
				<ImageComponent type={IMAGE_TYPE.TRANSFORM} progressBar={!isModelOpen} progress={progress} hasError={hasError} alt="Your Anime Photo" imageState={transformedImage} />
				{/* <TrialImageComponent onClick={handleChange} imgList={TRIAL_IMGS_LOCAL} sideText="chooseHero" /> */}
				<ButtonListComponent buttonList={transformedImgBtnList}></ButtonListComponent>
				{/* <div className="container display-flex transform-btn-container">
					<div className="container genbtn">
						<label className={base64Img && !isLoading ? "btn btn-transfer" : "btn btn-transfer-disabled"} onClick={() => handleTransfer(base64Img)}>
							{isLoading ? translate("generating") : translate("generate")}
						</label>
					</div>
					<AutorigButton type={IMAGE_TYPE.NAVIGATE} btnClassName="btn-primary" disabled={!transformedImage} onClick={() => { navigate(getUrlParameter(transformedImage), { state: imgResult }); }}>{translate("shareAnimePhoto")}</AutorigButton>
					<div className="container display-flex display-flex-col">
						<a className={`btn btn-primary ${!transformedImage ? "btn-disabled" : ""}`} href={transformedImage} target="_blank" download rel="noopener noreferrer">{translate("downloadAnimePhoto")}</a>
					</div>
				</div> */}
			</div>
		);
	}
	const handleOpenModel = () => {
		setModelOpen(!isModelOpen);
		setSecond(-1);
	}
	const transformedImgBtnList = [
		// {
		// 		id: "transfer",
		// 		icon:"fa-solid fa-arrows-rotate",
		// 		text: "generate",
		// 		action: () => { handleTransfer(base64Img) }
		// },
		{
			id: "share",
			icon: "fa-solid fa-share",
			text: "shareAnimePhoto",
			action: () => {
				if (transformedImage) {
					//navigate(getUrlParameter(transformedImage), { state: imgResult });
					const fbBtn = document.getElementById('fb-share');
					fbBtn?.click();
				}
			}
		},
		{
			id: "download",
			icon: "fa-solid fa-download",
			text: "downloadAnimePhoto",
			action: () => {
				if (!transformedImage) return;
				// const url = transformedImage;
				// const link = document.createElement('a');
				// link.href = url;
				// link.target = '_blank';
				// link.rel = 'noopener noreferrer';
				// link.setAttribute('download', 'your-image');
				// document.body.appendChild(link);
				// link.click();
				// link.parentNode?.removeChild(link);
				// window.URL.revokeObjectURL(url);
				fetch(transformedImage, {
					method: "GET",
					headers: {}
				  })
					.then(response => {
					  response.arrayBuffer().then(function(buffer) {
						const url = window.URL.createObjectURL(new Blob([buffer]));
						const link = document.createElement("a");
						link.href = url;
						link.setAttribute("download", "image.png"); //or any other extension
						document.body.appendChild(link);
						link.click();
					  });
					})
					.catch(err => {
					  console.log(err);
					});
			}
		}
	]

	return (
		<div className="fs-container">
			<QueueModal isModelOpen={isModelOpen} queue={queue} second={second} onRequestClose={handleOpenModel} progress={progress} hasError={hasError}></QueueModal>
			<div className="fs-title container display-flex-col">
				<p className="main-text quick-overview-title">{translate('freefsonline')}</p>
				<p className="sub-text">{translate('freefsonlineDes')}</p>
			</div>
			<div className="fs-component container">
				<div className="fs-tranform container">
					<div className="fs-tranform-component container display-flex-col">
					<div className="fs-tranform-img-container container">
								<UploadImageComponent />
								<div className="container choose-hero-icon-container">
									<i className="fa-solid fa-chevron-right"></i>
									<i className="fa-solid fa-chevron-right"></i>
								</div>
								<DemoImageComponent />
							</div>
							<div className="tool-tip container">
								<i className="fa-solid fa-circle-info"></i> <span className="tooltip-text">{translate('imageTooltip')}</span>
							</div>
							<div className="container genbtn">
								<label className={base64Img && !isLoading ? "btn btn-transfer" : "btn btn-transfer-disabled"} onClick={() => handleTransfer(base64Img,base64Target)}>
									{isLoading ? translate("generating") : translate("generate")}
								</label>
							</div>
					</div>
					<div className="fs-result-img">
						<ImageComponent type={IMAGE_TYPE.TRANSFORM} progressBar={!isModelOpen} progress={progress} hasError={hasError} alt="Your Photo" imageState={transformedImage || tryImg.img} />
						<ButtonListComponent buttonList={transformedImgBtnList} css={transformedImage ? "button-show" : "button-hide"}></ButtonListComponent>
						<FacebookShareButton
						id="fb-share"
								url={convertImgSrc(imgResult.url_share_page)}
								hashtag={"#1clickfaceswap"}
								className="container display-flex-row display-flex hidden"
							>
								<AutorigButton type={IMAGE_TYPE.NAVIGATE} btnClassName="share-btn" onClick={() => { }}>
									<FacebookIcon size={30} round />
									<span className='share-fb'>{translate('fbShare')}</span>
								</AutorigButton>
							</FacebookShareButton>
					</div>
				</div>
				<div className="fs-selection">
				<TrialImageComponent onClick={setTryImg} onSelect={setBase64Target} imgList={getImgList(REF_IMGS)} activeImg={tryImg}/>
				</div>
			</div>
		</div>
	);
};

export default ImageTransformation;