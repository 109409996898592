import { useEffect, useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import LazyLoad from 'react-lazyload';
import ImageComponent from './ImageComponent';
import { IMAGE_TYPE } from '../../constant/constant';
import { FileUploader } from 'react-drag-drop-files';
import { resizeFile } from '../../utils/util';

const TrialImageComponent = (props) => {
	const { onClick, imgList = [], activeImg, onSelect } = props;
	const [activeItem, setActiveItem] = useState(activeImg);
	const [targetImg, setTargetImg] = useState(undefined);
	const [isMobile, setIsMobile] = useState(false);
	const size = Math.floor(imgList.length / 3);
	const remainder = imgList.length % 3;
	const anchor = size + (remainder > 0 ? 1 : 0)
	const part1 = imgList.slice(0, anchor);
	const part2 = imgList.slice(anchor, 2 * anchor);
	const part3 = imgList.slice(2 * anchor);
	useEffect(() => {
		if (window.innerWidth <= 1038) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	}, [activeItem, setActiveItem, isMobile]);
	const handleChange = async (event: any) => {
		try {
			const file = event.target?.files[0] || event;
			const image = await resizeFile(file);
			setTargetImg(image);
			onClick({img:image,id:'user_target'});
			onSelect(image);
			setActiveItem({img:'',id:''});
		} catch (err) {
			console.log(err);
		}
	};
	const handleOnImageRemove = () => {
		setTargetImg(undefined);
		onSelect(undefined);
	};
	const UploadImageComponent = () => {
		const fileTypes = ["JPG", "PNG"];

		return (
			<div className="container display-flex-col target-image-container">
				{targetImg && (<ImageComponent type={IMAGE_TYPE.UPLOAD} progressBar={false} alt={"Your Upload Photo"} imageState={targetImg} onClose={handleOnImageRemove} />)}
				{!targetImg && (<FileUploader handleChange={handleChange} fileTypes={fileTypes} name="file" classes="drag-drop-img fs-img-holder container" />)}

			</div>
		);
	}
	return (
		isMobile ? <div className="gallery-container fs-gallery-container">
			{imgList.map((item, index) => {
				return index === 0 ? <UploadImageComponent /> : (
					<div className="gallery-try-item" key={`gallery-item-${index + 1}`}>
						<LazyLoad
							height={50}
							offset={100}
							scrollContainer={'.fs-gallery-container'}
							once>
							<img className={`gallery-img ${item.id === activeItem.id ? "gallery-try-item-active" : ""}`} src={item.img} alt={item.id} onClick={() => { onClick(item); setActiveItem(item) }} />
						</LazyLoad>
					</div>
				)
			})}
		</div> : <div className="gallery-container fs-gallery-container">
			{
				[part1, part2, part3].map((part) => {
					return (
						<div className='container display-flex-col fs-gallery-col'>
							{
								part.map((item, index) => {
									return item.id === 'targetImg' ? <UploadImageComponent /> : (
										<div className="gallery-try-item" key={`gallery-item-${index + 1}`}>
											<img className={`gallery-img ${item.id === activeItem.id ? "gallery-try-item-active" : ""}`} src={item.img} alt={item.id} onClick={() => { onClick(item); setActiveItem(item) }} />
										</div>
									)
								})
							}
						</div>
					)
				})
			}
		</div>

	);
};

export default TrialImageComponent;